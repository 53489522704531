.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.page-main-container {
    background: #171717;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 120px;
    padding-bottom: 120px;
}

.lockr-logo-container {
    position: absolute;
    top: 32px;
}

.lockr-logo {
    width: 80px;
    height: 30px;
}

.content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.welcome-title {
    color: white;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.welcome-message {
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    margin-top: 14px;
    line-height: 24px;
    max-width: 600px;
    font-style: normal;
    text-align: center;
}

.options-container {
    margin-top: 60px;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
}

.option-parent {
    background-color: #26282A;
    height: 288px;
    width: 400px;
}

.width-40 {
    width: 40px;
}

.option-logo-container-lockrmail {
    width: 400px;
    height: 100px;
    background: linear-gradient(0deg, rgba(97, 206, 247, 0.1), rgba(97, 206, 247, 0.1)), #000000;
    border: 1px solid #61CEF7;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.option-logo-container-lockrscan {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba(80, 232, 177, 0.1), rgba(80, 232, 177, 0.1)), #000000;
    border: 1px solid #50E8B1;
}

.options-logo {
    width: 48px;
    height: 48px;
}

.options-title-container {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
}

.options-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-top: 4px;
    color: #8B8B8B
}

.options-body-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 188px;
}

.options-button {
    width: 160px;
    height: 40px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-top: 24px;
    color: #26282A;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #61CEF7;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
}

.chrome-extension-container {
    max-width: 794px;
    width: 794px;
    height: 60px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    background-color: #26282A;
    padding: 24px;
    position: relative;
}

.chrome-extension-title-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
}

.chrome-extension-subtitle {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #8B8B8B;
    margin-top: 4px;
}

.chrome-extension-title {
    color: white;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    font-family: 'Montserrat', sans-serif;
}

.chrome-extension-install-button {
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 2px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    width: 160px;
    cursor: pointer;
    height: 40px;
    z-index: 200;
}

.upcoming-feature-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    padding-bottom: 120px;
}

.upcoming-feature-parent {
    height: 100px;
    width: 290px;
    display: flex;
    flex-direction: row;
    background: #171717;
    border: 1px solid #353738;
    border-radius: 2px;
}

.upcoming-feature-logo-container-lockrpay {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(250, 199, 72, 0.1);
    box-sizing: border-box;
    border: 1px solid #FAC748;
}

.upcoming-feature-logo-container-lockrtext {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background: rgba(189, 148, 188, 0.1);
    border: 1px solid #BD94BC;
}

.upcoming-feature-title-container {
    width: 190px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
}

.upcoming-feature-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #FFFFFF;
    opacity: 0.5;
}

.upcoming-feature-img {
    height: 40px;
    width: 40px;
}

.upcoming-feature-coming-soon {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 92px;
    height: 23px;
    background: #26282A;
    border-radius: 100px;
}

.chrome-extension-background-img {
    position: absolute;
    bottom: 0px;
    right: 60px;
    z-index: 100;
    height: 100px;
    width: 300px;
}

.dots-img {
    position: absolute;
    right: 0px;
    top: 45%;
    z-index: 100;
}

.footer-container {
    position: fixed;
    bottom: 0px;
    width: calc(100% - 180px);
    height: 70px;
    display: flex;
    align-items: center;
    background: #1D1D1D;
    padding-left: 90px;
    padding-right: 90px;
    z-index: 300;
}

.lock-logo {
    width: 19px;
    height: 30px;
}

.lock-logo-container {
    display: flex;
    flex: 1;
}

.privacy-policy-text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #828282;
    cursor: pointer;
}

.copyright-text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-left: 16px;
    line-height: 15px;
    color: #828282;
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .page-main-container {
        background: #171717;
        height: 100vh;
        width: calc(100vw - 40px);
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 120px 20px 180px 20px;
    }

    .lockr-logo-container {
        position: absolute;
        top: 32px;
    }

    .upcoming-feature-container {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
    }

    .chrome-extension-container {
        width: calc(100% - 78px);
        min-height: 258px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        background-color: #26282A;
        padding: 24px;
        position: relative;
    }

    .chrome-extension-background-img{
        position: absolute;
        bottom: 0px;
        left: 4%;
        z-index: 50;
    }

    .chrome-extension-title {
        width: 100%;
        text-align: center;
    }

    .chrome-extension-subtitle{
        width: 100%;
        text-align: center;
    }

    .chrome-extension-title-container {
        display: flex;
        flex-direction: column;
        max-height: 120px;
        position: relative;
    }

    .options-container {
        margin-top: 60px;
        width: calc(100%);
        flex-direction: column;
    }

    .option-parent {
        height: 288px;
        width: 90%;
    }

    .option-logo-container-lockrmail {
        width: 100%;
        height: 100px;
        background: linear-gradient(0deg, rgba(97, 206, 247, 0.1), rgba(97, 206, 247, 0.1)), #000000;
        border: 1px solid #61CEF7;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .width-40 {
        height: 40px;
    }

    .dots-img {
        display: none;
    }

    .upcoming-feature-parent {
        height: 100px;
        width: 100%;
        display: flex;
        flex-direction: row;
        background: #171717;
        border: 1px solid #353738;
        border-radius: 2px;
    }

    .option-logo-container-lockrscan {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        background: linear-gradient(0deg, rgba(80, 232, 177, 0.1), rgba(80, 232, 177, 0.1)), #000000;
        border: 1px solid #50E8B1;
    }

    .options-title-container {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        font-family: 'Montserrat', sans-serif;
        line-height: 36px;
        text-align: center;
        color: #FFFFFF;
    }

    .lockr-logo {
        width: 80px;
        height: 30px;
    }

    .content-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .welcome-title {
        color: white;
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        text-align: center;
        max-width: 350px;
        font-family: 'Montserrat', sans-serif;
    }

    .lock-logo {
        width: 19px;
        height: 30px;
    }

    .lock-logo-container {
        display: flex;
        flex: 1;
    }

    .footer-container {
        position: fixed;
        bottom: 0px;
        width: calc(100% - 40px);
        height: 70px;
        display: flex;
        align-items: center;
        background: #1D1D1D;
        padding-left: 20px;
        padding-right: 20px;
    }

    .upcoming-feature-logo-container-lockrpay {
        height: 100px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(250, 199, 72, 0.1);
        border: 1px solid #FAC748;
    }


    .upcoming-feature-title-container {
        width: 190px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 16px;
    }


}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {

}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {

}
